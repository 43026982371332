<template>
  <div id="app">
    <div id="nav">
      <vs-navbar text-color="white" class="nabarx">
        <div id="title" slot="title">
          <vs-navbar-title>
            <a @click.prevent="scrollToTop">
              <img src="./assets/images/IslamicTimes.svg" alt="IslamicTimes">
            </a>
          </vs-navbar-title>
        </div>

        <!-- <vs-navbar-item index="0">
          <a class="navItem" @click.prevent="toBenefit">Benefit</a>
        </vs-navbar-item> -->
        <vs-navbar-item index="1">
          <a class="navItem" @click.prevent="toFitur">Fitur</a>
        </vs-navbar-item>
        <vs-navbar-item index="2">
          <a class="navItem" @click.prevent="toTestimoni">Testimoni</a>
        </vs-navbar-item>
       <!--  <vs-navbar-item index="3">
          <a class="navItem" @click.prevent="toFAQ">FAQ</a>
        </vs-navbar-item> -->
        <vs-navbar-item index="4">
          <a href="http://bit.ly/islamic-times" target="_blank">
            <div id="btnSignIn">
              <vs-button color="#1E715D" type="border">Unduh Aplikasi</vs-button>
            </div>
          </a>
        </vs-navbar-item>

      </vs-navbar>
    </div>
    
    <transition name="fade">
      <router-view id="isi" />
    </transition>
  </div>
</template>

<script>
  document.title = 'Landing Page - Islamic Times'

  export default {
    data() {
      return {
        namaRoute: this.$router.currentRoute.name,
        delay: 700,
      }
    },
    methods: {
      scrollToTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
        this.$router.push('/')
      },
      toBenefit() {
        this.$router.push('/')
        setTimeout(() => {
          this.$scrollTo('#benefit', 500, {offset: -100})
        }, this.delay)
      },
      toFitur() {
        this.$router.push('/')
        setTimeout(() => {
          this.$scrollTo('#fitur', 500, {offset: -100})
        }, this.delay)
      },
      toTestimoni() {
        this.$router.push('/')
        setTimeout(() => {
          this.$scrollTo('#testimoni', 500, {offset: -100})
        }, this.delay)
      },
      toFAQ() {
        this.$router.push('/')
        setTimeout(() => {
          this.$scrollTo('#FAQ', 500, {offset: -100})
        }, this.delay)
      },
    },
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;600&display=swap');

  html,
  body {
    height: 100%;
    width: 100%;
  }

  #isi {
    padding-top: 6%;
  }

  .vs-navbar--btn-responsive .btn-responsive-line {
    background: white;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    height: 100%;
    background: linear-gradient(80.01deg, #23836D 0%, #5ED4B9 124.65%);
  }

  #nav {
    color: white;
    position: fixed;
    z-index: 5;
    width: 100%;
    background: linear-gradient(80.01deg, #23836D 0%, #5ED4B9 124.65%);

    a {
      padding: 20px;
      font-family: Mukta;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.15000000596046448px;
      text-align: left;
      cursor: pointer;
    }
  }

  .nabarx {
    padding: 1%;
  }

  #btnSignIn {
    background-color: white;
    border-radius: 7.3px;
    box-shadow: 4px 4px 4px rgba(255, 255, 255, 0.1);
  }

  @media (min-width: 801px) {
    #btnSignIn {
      width: 150px;
    }
  }

  .vs-button {
    font-family: Mukta;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 1.25px;
    width: 100%;
    height: 42px;
  }

  .navItem {
    margin-right: 50px;
  }

  @media (min-width: 801px) {
    .nabarx {
      padding-left: 8%;
      padding-right: 8%;
    }
  }

  @media (max-width: 991px) {
    .navItem {
      margin-right: 20px;
    }
  }

  @media (max-width: 850px) {
    .navItem {
      margin-right: 10px;
    }
  }
</style>