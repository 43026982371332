<template>
  <div class="home">
    <!-- Header -->
    <header class="section1 container my-5 justify-content-center">
      <vs-row id="kontenHeader" vs-justify="center" vs-align="center">
        <vs-col id="bacaanAwal" vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <div class="hello">
            <h1>Pengingat Waktu <br> Sholat Terbaikmu! </h1>
            <p id="kataPembuka">Kami hadir untuk membantu peribadahan dengan
              membangun Aplikasi yang bermanfaat, praktis serta
              dapat digunakan kapanpun dan dimanapun.</p>
            <vs-row id="btn-group">
              <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="3">
                <div id="btn">
                  <vs-button id="btnGetStarted" color="#008A85" type="border">Mulai</vs-button>
                </div>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="3">
                <vs-button id="btnPelajari" color="#1E715D" text-color="white" type="flat">
                  <vs-row>
                    <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="6">
                      Pelajari <vs-icon icon="arrow_right_alt"></vs-icon>
                    </vs-col>
                  </vs-row>
                </vs-button>
              </vs-col>
            </vs-row>
          </div>
          <div class="illustrasi-wrapper d-lg-block d-none">
            <img id="imageAwal" src="../assets/images/headImage.svg" alt="Gambar Awal" />
          </div>
        </vs-col>
      </vs-row>
    </header>

    <!-- Section Fitur-fitur -->
    <section id="fitur">
      <h6 class="JdlSection">FITUR-FITUR</h6>
      <h4 class="penjSection">Apa Saja yang Anda Dapatkan?</h4>
      <vs-row class="mt" vs-justify="center">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="6" vs-xs="6">
          <div class="ItemFungsi">
            <img src="../assets/images/iconFungsi/fitur1.svg" alt="Gambar Fungsi">
            <h6 class="jdlFungsi">Waktu Sholat</h6>
            <p class="isiFungsi">Dapat memberitahu waktu sholat kapanpun </p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="6" vs-xs="6">
          <div class="ItemFungsi">
            <img src="../assets/images/iconFungsi/fitur2.svg" alt="Gambar Fungsi">
            <h6 class="jdlFungsi">Arah Kiblat</h6>
            <p class="isiFungsi">Dapat memberitahu arah kiblat dimanapun</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="6" vs-xs="6">
          <div class="ItemFungsi">
            <img src="../assets/images/iconFungsi/fitur3.svg" alt="Gambar Fungsi">
            <h6 class="jdlFungsi">Gerhana</h6>
            <p class="isiFungsi">Dapat memberitahu waktu gerhana</p>
          </div>
        </vs-col>
      </vs-row>
      <vs-row class="mt" vs-justify="center">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="6" vs-xs="6">
          <div class="ItemFungsi">
            <img src="../assets/images/iconFungsi/fitur4.svg" alt="Gambar Fungsi">
            <h6 class="jdlFungsi">Awal Bulan Qomariyah</h6>
            <p class="isiFungsi">Dapat memberitahu penanggalan Bulan Qomariyah</p>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="6" vs-xs="6">
          <div class="ItemFungsi">
            <img src="../assets/images/iconFungsi/fitur5.svg" alt="Gambar Fungsi">
            <h6 class="jdlFungsi">Data Matahari Dan Bulan</h6>
            <p class="isiFungsi">Dapat memberitahu data Matahari & Bulan</p>
          </div>
        </vs-col>
      </vs-row>
    </section>
    <hr>

    <!-- Section Testimoni -->
    <section id="testimoni">
      <h6 class="JdlSection">TESTIMONI</h6>
      <h4 class="penjSection">Apa Kata Mereka</h4>
      <vs-row vs-justify="center">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center">
          <splide :options="options" id="slideKomen">
            <splide-slide>
              <KomenItem
                :nama="'Alamsyah SP'"
                :gbr="'https://play-lh.googleusercontent.com/a-/AOh14Gj7lZbi4AZEaBrsyaDt0mQugjGFaRRROPA8PDJL8A=w48-h48-n-rw'"
                :komen="'Alhamdulillah,... semoga menjadi \'amal jariyah bagi semua para pembuat nya. Sebagai anggota PERSIS, Saya sangat senang dengan kehadiran aplikasi ini, setelah sebelumnya memakai aplikasi Buatan \'yang lain\''"
                :ket="''"
              />
            </splide-slide>
            <splide-slide>
              <KomenItem
                :nama="'Fauzan Asrul'"
                :gbr="'https://play-lh.googleusercontent.com/a-/AOh14GipOBNH9eZTHHllUc4YgCw7pY3vN2u_UDIITPJJBw=w48-h48-n-rw'"
                :komen="'Aplikasi bagus, ringan, simpel mudah digunakan, walaupun dalam tahap pengembangan, saran, tambah aplikasi buat baca Al-Qur\'annya jga, terimakasih semoga menjadi ladang amal buat penggagas dan pembuat aplikasi ini'"
                :ket="''"
              />
            </splide-slide>
            <splide-slide>
              <KomenItem
                :nama="'Rika Wahyudin'"
                :gbr="'https://play-lh.googleusercontent.com/a/AATXAJw91BHh2jKUKxKGJq_4-BzOU2viHv5-_yyRwpHB=w48-h48-n-rw-mo'"
                :komen="'Begitu dapat kabar telah terbitmya aplikasi ini, bersesuaian dg 100 tahun hadirnya PERSIS di Indonesia, saya langsung download dan instal..Maju terus Persis dalam mengembangkan Dakwah Islam di tanah air.'"
                :ket="''"
              />
            </splide-slide>
            <splide-slide>
              <KomenItem
                :nama="'Robbi Rodliya'"
                :gbr="'https://play-lh.googleusercontent.com/a-/AOh14Ghw9geM5_sW_PcojxcN0F2EmU0IwAjgzz5GvgV0pA=w48-h48-n-rw'"
                :komen="'Terobosan yang sangat bagus, sangat sesuai dengan kebutuhan umat. Request fitur widget supaya jadwal salat bisa tampil di halaman depan tanpa buka aplikasi, dan pengingatnya supaya bisa disetel beberapa menit sebelum waktu salat. Terimakasih banyak'"
                :ket="''"
              />
            </splide-slide>
            <splide-slide>
              <KomenItem
                :nama="'Soni Sutisna'"
                :gbr="'https://play-lh.googleusercontent.com/a/AATXAJyx_kSRgnWj1BJjYqQkmiTAxxQoJGzpYfntxuvm=w48-h48-n-rw-mo'"
                :komen="'Mangtaf semoga menjadi amal jariyah untuk semua yang terlibat dalam pembuatan aplikasi ini'"
                :ket="''"
              />
            </splide-slide>
          </splide>
        </vs-col>
      </vs-row>
    </section>
    <!-- <hr> -->

    <!-- Section FAQ -->
    <!-- <section id="FAQ" class="faq">
      <vs-row vs-justify="center">
        <vs-col class="itemfaq" vs-lg="12" vs-sm="6" vs-xs="10">
          <h6 id="secfaq">FREQUENTLY ASKED QUESTION</h6>
          <p class="textfaq">
            Bagi anda yang ingin tahu lebih tentang Islamic times, anda dapat melihat <br>
            pada kolom dibawah ini.
          </p>
        </vs-col>
      </vs-row>
      <vs-row vs-justify="center">
        <vs-col class="itemfaq" type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="6" vs-xs="11">
          <div class="card">
            <img class="icon1" src="../assets/images/ion_help-circle-outline.svg" alt="">
            <p class="nofaq">Apa Itu Islamic Times?</p>
            <p id="tfaq">Islamic Times adalah Aplikasi untuk
              memudahkan kita dalam beribadah.</p>
          </div>
        </vs-col>
        <vs-col class="itemfaq" type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="6" vs-xs="11">
          <div class="card">
            <img class="icon1" src="../assets/images/ion_help-circle-outline.svg" alt="">
            <p class="nofaq">Kapan kita menggunakan Islamic Times?</p>
            <p id="tfaq">Islamic Times dapat digunakan kapanpun.</p>
          </div>
        </vs-col>
      </vs-row>
      <vs-row vs-justify="center">
        <vs-col class="itemfaq" type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="6" vs-xs="11">
          <div class="card">
            <img class="icon1" src="../assets/images/ion_help-circle-outline.svg" alt="">
            <p class="nofaq">Mengapa Islamic Times?</p>
            <p id="tfaq">Karena Islamic Times memiliki fitur yang bermanfaat.</p>
          </div>
        </vs-col>
        <vs-col class="itemfaq" type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="6" vs-xs="11">
          <div class="card">
            <img class="icon1" src="../assets/images/ion_help-circle-outline.svg" alt="">
            <p class="nofaq">Siapa yang menggunakan Islamic Times?</p>
            <p id="tfaq">Islamic Times dapat digunakan oleh siapapun.</p>
          </div>
        </vs-col>
      </vs-row>
      <vs-row vs-justify="center">
        <vs-col class="itemfaq" type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="6" vs-xs="11">
          <div class="card">
            <img class="icon1" src="../assets/images/ion_help-circle-outline.svg" alt="">
            <p class="nofaq">Bagaimana fitur Islamic Times?</p>
            <p id="tfaq">Sangat mudah, di halaman utama anda
              dapat memilih fitur yang dinginkan</p>
          </div>
        </vs-col>
        <vs-col class="itemfaq" type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="6" vs-xs="11">
          <div class="card">
            <img class="icon1" src="../assets/images/ion_help-circle-outline.svg" alt="">
            <p class="nofaq">Dimana kita menggunakan Islamic Times?</p>
            <p id="tfaq">Islamic Times dapat digunakan dimanapun.</p>
          </div>
        </vs-col>
      </vs-row>
    </section> -->

    <!-- Section Tunggu Apa lagi -->
    <section class="sectunggu">
      <vs-row vs-justify="center">
        <vs-col vs-lg="12" vs-sm="12" vs-xs="10">
          <h4 id="headtng">Tunggu Apa Lagi ?</h4>
          <p id="txttunggu">
            Bagi Anda, yang sedang mencari solusi untuk memudahkan melaksanakan kegiatan ibadah <br>
            Anda gunakan kesempatan ini untuk mencoba semua fitur Islamic Times App.
          </p>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="left" vs-lg="12" vs-sm="12" vs-xs="12">
          <a id="btndownload" href="http://bit.ly/islamic-times" target="_blank">
            <div>
              <vs-button color="#3ECCAA" type="border">Unduh Aplikasi</vs-button>
            </div>
          </a>
        </vs-col>
      </vs-row>
    </section>

    <!-- Footer -->
    <footer class="footer">
      <vs-row class="container pt-5" vs-justify="left" vs-align="start">
        <vs-col vs-type="flex" vs-justify="center" vs-align="start" vs-lg="3" vs-sm="6" vs-xs="12">
          <div class="secinfo">
            <img src="../assets/images/IslamicTimes.svg" id="imgfooter" alt="footerIslamicTimes">
            <p>
              <a id="linkinfo" style="yellow" href="#">info@someah.id</a>
            </p>
            <p id="linkwa">
              <a href="#">+628562294222</a>
            </p>
            <a id="iconig" href="#"><img src="../assets/images/uil_instagram-alt.svg" alt="iconinstagram"></a>
            <a id="iconlin" href="#"><img src="../assets/images/carbon_logo-linkedin.svg" alt="iconlinkedlin"></a>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="6" vs-xs="12">
          <div id="secperu">
            <h5 class="jdl">Tentang Kami</h5>
            <a href="https://someah.id/" target="_blank" class="f-content">Someah Kreatif Nusantara</a><br>
            <a href="#" class="f-content">Dewan Hisab dan Rukyat Persis</a>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="6" vs-xs="12">
          <div id="secisl">
            <h5 class="jdl1">Islamic</h5>
            <a href="#" class="f-content">Fitur</a><br>
            <a href="#" class="f-content">Panduan</a><br>
            <a href="" class="f-content">FAQ</a>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="6" vs-xs="12">
          <div id="sectersedia">
            <h5 class="jdl2">Tersedia Di</h5>
            <a href="http://bit.ly/islamic-times" target="_blank"><img src="../assets/images/LogoPlaystore.svg" id="iconplay" alt="LogoPlayStore"></a><br>
          </div>
        </vs-col>
      </vs-row>
      <div class="akhiran" vs-lg="12" vs-sm="6" vs-xs="12">
        <hr class="linefooter">
        <p class="txtfooter">© 2020 PT Someah Kreatif Nusantara. Hak Cipta dilindungi oleh Undang-Undang | <a class="text-bluelight" href="/privacy-policy" @click.prevent="halPrivasi()">Kebijakan Privasi</a></p>
      </div>
    </footer>

    <!-- Scroll Up -->
    <ScrollUp :scroll-duration="400" :scroll-y="350" />
  </div>
</template>

<script>
  // @ is an alias to /src
  import KomenItem from '@/components/KomenItem.vue'
  import ScrollUp from 'vue-scroll-up'
  import {
    Splide,
    SplideSlide
  } from '@splidejs/vue-splide'
  import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css'
  import '@/assets/scss/hiashome.scss'

  export default {
    name: 'Home',
    data() {
      return {
        options: {
          rewind: true,
          autoWidth: true,
          autoplay: true,
          pagination: false
        },
      };
    },
    components: {
      KomenItem,
      Splide,
      SplideSlide,
      ScrollUp
    },
    methods: {
      halPrivasi() {
        this.$router.push('/privacy-policy')
        window.scrollTo({
          top: 0,
        })
      },
    },
  }
</script>

<style lang="scss">
  /**
   * Container Responsive
   */
  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }

  /**
   * Slide Komentar Responsive
   */
  @media (max-width: 800px) {
    #slideKomen {
      width: 500px;
    }
  }

  @media (max-width: 500px) {
    #slideKomen {
      width: 400px;
    }

    .section1 {
      min-height: 90vh;
    }

    .min-mt {
      margin-top: 0px;
    }

    #btn-group>.vs-col {
      width: 50% !important;
    }
  }

  @media (max-width: 390px) {
    #slideKomen {
      width: 310px;
    }
  }

  @media (max-width: 300px) {
    #slideKomen {
      width: 275px;
    }
  }

  @media (max-width: 275px) {
    #slideKomen {
      width: 250px;
    }
  }

  /**
   * Image Awal Responsive
   */
  @media (min-width: 850px) and (max-width: 1245px) {
    .illustrasi-wrapper {
      flex: 0 0 45% !important;
      display: none;
    }

    #imageAwal {
      width: 450px;
    }

    .illustrasi-wrapper>img {
      width: 100%;
      margin-top: 20px;
      margin-left: -20px;
    }
  }

  @media (min-width: 992px) {
    .illustrasi-wrapper {
      flex: 0 0 45% !important;
      display: none;
    }

    .d-lg-block {
      display: block !important;
    }

    .illustrasi-wrapper>img {
      width: 100%;
      margin-top: 20px;
      margin-left: -20px;
    }
  }

  /**
   * Lainnya Responsive
   */
  @media (max-width: 600px) {
    .buatfooter {
      text-align: center;

    }

    .secinfo,
    #secperu,
    #secisl {
      text-align: center;
      padding: 5%;
    }

    #btndownload {
      margin-bottom: 10%;
    }

    .hello {
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
    }

    #kataPembuka {
      width: 100%;
    }
  }

</style>