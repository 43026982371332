<template>
    <div class="ItemKomen">
        <p id="isiKomen">{{ komen }}</p>
        <img id="gbrKomen" :src="gbr" alt="Gambar Profile">
        <h6 id="namaKomen">{{ nama }}</h6>
        <p id="ketKomen">{{ ket }}</p>
    </div>
</template>

<script>
    export default {
        name: "KomenItem",
        props: ['nama', 'komen', 'ket', 'gbr'],
    }
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Oxygen&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;600&display=swap');

    .ItemKomen {
        width: 693px;
        min-height: 338px;
        max-height: fit-content;
        margin: 2px;
        background: #FFFFFF;
        border: 1px solid #C4C4C4;
        box-sizing: border-box;
        border-radius: 25px 25px 25px 25px;
    }

    #isiKomen {
        margin-top: 50px;
        margin-right: 76px;
        margin-left: 76px;
        font-family: Oxygen;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        color: #52575C;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: center;
    }

    #gbrKomen {
        margin-top: 55px;
        margin-bottom: 24px;
        border-radius: 50%;
    }

    #namaKomen {
        font-family: Mukta;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 8px;
        letter-spacing: 0.15000000596046448px;
        text-align: center;
    }

    #ketKomen {
        font-family: Mukta;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: #999999;
        margin-bottom: 31px;
        text-align: center;
    }

    @media (max-width: 800px) {
        .ItemKomen {
            width: 400px;
        }
    }

    @media (max-width: 500px) {
        .ItemKomen {
            width: 300px;
            height: 500px;
        }
        #isiKomen {
            margin-left: 20px;
            margin-right: 20px;
        }
    }

    @media (max-width: 390px) {
        .ItemKomen {
            width: 212px;
            height: 95%;

            #isiKomen {
                font-size: 12px;
            }

            #gbrKomen {
                width: 50px;
            }

            #namaKomen {
                font-size: 15px;
            }

            #ketKomen {
                font-size: 13px;
            }
        }
    }

    @media (max-width: 300px) {
        .ItemKomen {
            width: 175px;
            height: 95%;

            #gbrKomen {
                width: 45px;
            }

            #namaKomen {
                font-size: 13px;
            }

            #ketKomen {
                font-size: 12px;
            }
        }
    }

    @media (max-width: 275px) {
        .ItemKomen {
            width: 150px;
            height: 95%;

            #isiKomen {
                padding-right: 10px;
                padding-left: 10px;
            }

            #gbrKomen {
                width: 45px;
            }

            #namaKomen {
                font-size: 13px;
            }

            #ketKomen {
                font-size: 12px;
            }
        }
    }
</style>